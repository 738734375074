import Vue from 'vue';
import { dayjs } from '@/plugins/dayjs';
import { dateFormatUTC, dateFormatYMD } from '@/utils/helpers/dates';
import { cloneDeep } from '@/utils/helpers/methods';
import { formatPayment } from '@/utils/helpers/payment';
import { preciseCardinality } from '@/services/elastic';
import { HTTP, SEARCH } from '@/utils/http';

const defaultAggs = {
  filtered: {
    company: {
      gross: {
        value: 0,
      },
      fees: {
        value: 0,
      },
    },
    count: {
      value: 0,
    },
    potentialAgencies: {
      potentialAgenciesCount: {
        value: 0,
      },
    },
    potentialAgents: {
      potentialAgentsCount: {
        value: 0,
      },
    },
    unmatched: {
      total: {
        value: 0,
      },
    },
  },
};

const defaultPayment = {
  booking_id: null,
  date: new Date(),
  fee_amount: 0,
  fee_percentage: 0,
  potential_agency_ids: [],
  potential_agent_ids: [],
  type: 'check',
  _embedded: {
    batch: {},
    tags: {
      payment: [],
    },
  },
};

const state = {
  actions: [],
  dateFields: [
    { key: 'created_at', label: 'Created' },
    { key: 'date', label: 'Payment Date' },
    { key: 'matched_at', label: 'Match Date' },
    { key: 'reconciled_at', label: 'Reconcile Date' },
  ],
  aggs: cloneDeep(defaultAggs),
  deletedTags: [],
  editing: {},
  pagination: {
    page: 0,
    per: 25,
    total: 0,
  },
  payment: {},
  payments: [],
  queryFields: [
    { key: 'amount.text', label: 'Amount' },
    { key: 'client_full_name', label: 'Client Name' },
    { key: 'confirmation_number', label: 'Confirmation Number' },
    { key: 'ref_number', label: 'Reference Number' },
    { key: 'number', label: 'Sion Reference Number' },
    { key: 'supplier_name', label: 'Supplier' },
  ],
  refresh: false,
  sort: { created_at: 'desc' },
  sync: false,
};

const mutations = {
  // SET_UNMATCHED_PAYMENT(state, payment) {
  //   if (payment.date) {
  //     payment.date = dayjs(payment.date, dateFormatYMD).toDate();
  //   }
  //   state.payment = payment;
  // },
  RESET_UNMATCHED(state, payload) {
    switch (payload) {
      case 'editing':
        state.editing = cloneDeep(defaultPayment);
        break;
      case 'payment':
        state.payment = cloneDeep(defaultPayment);
        break;
      default:
    }
  },
  SET_UNMATCHED_ATOMIC(state, field) {
    const nodes = field.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, field.val);
      } else {
        obj = obj[node];
      }
    });
  },
  TOGGLE_UNMATCHED_PAYMENT_POTENTIAL(state, payload) {
    const { key } = payload;
    const index = state.editing[key].indexOf(payload.val);
    if (index < 0) {
      state.editing[key].push(payload.val);
    } else {
      state.editing[key].splice(index, 1);
    }
  },
  TOGGLE_UNMATCHED_PAYMENT_TAG(state, payload) {
    const { key, val } = payload;
    const { id } = val;
    const index = state[key]._embedded.tags.payment.findIndex(
      (paymentTag) => paymentTag.id === payload.val.id
    );
    const deletedIndex = state.deletedTags.findIndex((deletedTag) => deletedTag.id === id);
    if (index < 0) {
      state[key]._embedded.tags.payment.push(payload.val);
      if (deletedIndex >= 0) {
        state.deletedTags.splice(deletedIndex, 1);
      }
    } else {
      state[key]._embedded.tags.payment.splice(index, 1);
      if (deletedIndex < 0) {
        state.deletedTags.push(val);
      }
    }
  },
};

const getters = {
  unmatchedPayment: (state) => state.payment,
  unmatchedPaymentEditing: (state) => state.editing,
  unmatchedPaymentActions: (state) => state.actions,
  unmatchedPaymentDeletedTags: (state) => state.deletedTags,
  unmatchedPaymentRefresh: (state) => state.refresh,
  unmatchedPaymentSync: (state) => state.sync,
  unmatchedPayments: (state) => state.payments.map((payment) => {
    const unmatchedPayment = cloneDeep(payment);
    if (payment.currency && !payment._embedded.currency) {
      /* `currency` may not be embedded for unmatched payments made prior to an es index change on 5/14/2024 */
      unmatchedPayment._embedded.currency = payment.currency;
    }
    return unmatchedPayment;
  }),
  unmatchedPaymentsAggregations: (state) => state.aggs,
  unmatchedPaymentsPagination: (state) => state.pagination,
  unmatchedPaymentsEsQuery(state, getter, _rootState, rootGetters) {
    const company = rootGetters['companies/company'];
    const companyTimeZoneOffset = company.time_zone.offset;
    const employee = rootGetters['employees/currentEmployee'];
    const filters = rootGetters['commissions/commissionsFilters'];
    const unmatchedSort = getter.unmatchedPaymentsSort;
    const from = state.pagination.per * (state.pagination.page - 1);

    let dateFormat = dateFormatYMD;
    let toDate = null;
    let fromDate = null;
    if (filters.fields.date === 'created_at') {
      dateFormat = dateFormatUTC;
      fromDate = dayjs(filters.dates.from, dateFormat)
        .utcOffset(companyTimeZoneOffset)
        .startOf('day')
        .format(dateFormat);
      toDate = dayjs(filters.dates.to, dateFormat)
        .utcOffset(companyTimeZoneOffset)
        .endOf('day')
        .format(dateFormat);
    } else {
      fromDate = dayjs(filters.dates.from, dateFormat).format(dateFormat);
      toDate = dayjs(filters.dates.to, dateFormat).format(dateFormat);
    }

    const sort = [];
    let filterKey = null;
    if (unmatchedSort) {
      [filterKey] = Object.keys(unmatchedSort);
      const field = filterKey.match(/name|last_first/i) ? `${filterKey}.raw` : filterKey;
      const obj = {};
      obj[field] = { order: unmatchedSort[filterKey] };
      sort.push(obj);
    }
    sort.push({ '_embedded.batch_entry.sort_order': { order: 'asc' } });
    if (filterKey !== 'amount') {
      sort.push({ amount: { order: 'desc' } });
    }

    const filter = {
      bool: {
        must: [{ terms: { visible_to_ids: [company.id] } }],
        must_not: [],
        should: [],
      },
    };
    if (filters.dates.year !== 'all') {
      if (fromDate && toDate && !filters.invoice_id) {
        const dateQuery = { range: {} };
        dateQuery.range[filters.fields.date] = { gte: fromDate, lte: toDate };
        filter.bool.must.push(dateQuery);
      }
    }
    if (filters.hosts && filters.hosts.length > 0) {
      filter.bool.must.push({ terms: { '_embedded.company.id': filters.hosts } });
    }
    if (filters.unmatchedPotential && !filters.unmatchedPotential.includes('all')) {
      const potentialQuery = { bool: { should: [] } };
      if (filters.unmatchedPotential.includes('agency')) {
        potentialQuery.bool.should.push({ terms: { potential_agency_ids: [company.id] } });
      }
      if (filters.unmatchedPotential.includes('agent')) {
        potentialQuery.bool.should.push({ terms: { potential_agent_ids: [employee.id] } });
      }
      filter.bool.must.push(potentialQuery);
    }
    if (filters.statuses.unmatched && filters.statuses.unmatched.length > 0) {
      const terms = { status: filters.statuses.unmatched };
      filter.bool.must.push({ terms });
    }
    if (filters.batches && filters.batches.length > 0) {
      const ids = filters.batches.map((batch) => batch.id);
      const terms = { batch_id: ids };
      filter.bool.must.push({ terms });
    }
    if (filters.queryStrings && filters.queryStrings.length > 0) {
      const queryStringsQuery = {
        bool: {
          should: [],
        },
      };
      filters.queryStrings.forEach((query) => {
        if (query) {
          queryStringsQuery.bool.should.push({
            multi_match: {
              query: query.toUpperCase(),
              fields: filters.fields.query,
              type: 'cross_fields',
              operator: 'and',
            },
          });
        }
      });
      filter.bool.must.push(queryStringsQuery);
    }
    if (filters.tags.length > 0) {
      filters.tags.forEach((tag) => {
        filter.bool.must.push({
          term: { tags: tag },
        });
      });
    }
    if (filters.types && filters.types.length > 0) {
      const terms = { type: filters.types };
      filter.bool.must.push({ terms });
    }
    if (filters.query) {
      let { query } = filters;
      const should = [];
      if (parseInt(query, 10)) {
        should.push({ match: { amount: query } });
      } else {
        query = query.toLowerCase();
        should.push({ match_phrase_prefix: { client_full_name: query } });
        should.push({ match_phrase_prefix: { supplier_name: query } });
      }
      filter.bool.must.push({
        bool: {
          should,
        },
      });
    }
    const payload = {
      aggs: {
        count: preciseCardinality('id'),
        potentialAgencies: {
          filter: {
            bool: {
              must: [{ terms: { potential_agency_ids: [company.id] } }],
            },
          },
          aggs: {
            potentialAgenciesCount: {
              cardinality: {
                field: 'id',
              },
            },
          },
        },
        potentialAgents: {
          filter: {
            bool: {
              must: [{ terms: { potential_agent_ids: [employee.id] } }],
            },
          },
          aggs: {
            potentialAgentsCount: {
              cardinality: {
                field: 'id',
              },
            },
          },
        },
        filtered: {
          filter,
          aggs: {
            unmatched: {
              filter: {
                bool: {
                  must: [{ match: { status: 'unmatched' } }],
                },
              },
              aggs: {
                total: {
                  cardinality: {
                    field: 'id',
                  },
                },
              },
            },
            company: {
              filter: {
                bool: {
                  must: [{ terms: { '_embedded.company.id': [company.id] } }],
                },
              },
              aggs: {
                gross: {
                  sum: {
                    field: 'amount',
                  },
                },
                fees: {
                  sum: {
                    field: 'fee_total',
                  },
                },
              },
            },
          },
        },
      },
      size: state.pagination.per,
      sort,
      from: from >= 0 ? from : 0,
      query: {
        constant_score: {
          filter,
        },
      },
    };
    return payload;
  },
  unmatchedPaymentsFilters(state, getters, _rootState, rootGetters) {
    const filters = rootGetters['commissions/commissionsFilters'];
    return {
      ...filters,
      sort: getters.unmatchedPaymentsSort
    }
  },
  unmatchedPaymentsQueryFields: (state) => state.queryFields,
  unmatchedPaymentsDateFields: (state) => state.dateFields,
  unmatchedPaymentsSort: (state) => state.sort,
};

const actions = {
  async doCreateUnmatchedPayment({ commit }, params) {
    const { data } = await HTTP.post('/unmatched_payments', params);
    const [unmatched] = data.data.unmatched_payments;
    commit('SET_UNMATCHED_ATOMIC', { key: 'payment', val: formatPayment(unmatched) });
    return data;
  },
  async doDeleteUnmatchedPayment({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.delete(`/unmatched_payments/${id}`, { params });
    const [unmatched] = data.data.unmatched_payments;
    if (!ignore) commit('SET_UNMATCHED_ATOMIC', { key: 'payment', val: formatPayment(unmatched) });
    return data;
  },
  async doGetUnmatchedPayment({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.get(`/unmatched_payments/${id}`, { params });
    const [unmatched] = data.data.unmatched_payments;
    commit('SET_UNMATCHED_ATOMIC', { key: 'payment', val: formatPayment(unmatched) });
    return data;
  },
  async doGetUnmatchedPaymentActions({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.get(`/unmatched_payments/${id}/actions`, { params });
    if (!ignore) commit('SET_UNMATCHED_ATOMIC', { key: 'actions', val: data.data.actions });
  },
  async doSearchUnmatchedPayments({ commit }, params) {
    const { data } = await SEARCH.post('/sion_unmatched_payments/_search', params);
    commit('SET_UNMATCHED_ATOMIC', { key: 'aggs', val: data.aggregations });
    const unmatched = data.hits.hits.map((hit) => hit._source);
    commit('SET_UNMATCHED_ATOMIC', { key: 'payments', val: unmatched });
    return data;
  },
  async doUpdateUnmatchedPayment({ commit }, payload) {
    const { id, ignore, ...params } = payload;
    const { data } = await HTTP.patch(`/unmatched_payments/${id}`, params);
    const [unmatched] = data.data.unmatched_payments;
    if (!ignore) commit('SET_UNMATCHED_ATOMIC', { key: 'payment', val: formatPayment(unmatched) });
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
