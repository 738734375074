import Vue from 'vue';
import { dayjs } from '@/plugins/dayjs';
import { dateFormatYMD, dateFormatUTC } from '@/utils/helpers/dates';
import { compact, cloneDeep } from '@/utils/helpers/methods';
// import { preciseCardinality } from '@/services/elastic';
import { productionReportingFields, productionAggs, sortMethod } from '@/utils/helpers/es/clients';
import { cleanProductionData } from '@/utils/helpers/es/production_reporting';

const defaultAggs = {
  count: {
    value: 0
  },
  active: {
    doc_count: 0,
  },
  dates: {
    upcoming: {
      top: {
        hits: {
          total: {
            value: 0,
          },
        },
      },
    },
  },
  inactive: {
    doc_count: 0,
  },
  new: {
    doc_count: 0,
  },
};

const defaultClient = {
  address_string: null,
  agent_id: null,
  allergen_ids: [],
  commission_split: null,
  company_id: null,
  dietary_restriction_ids: [],
  type: 'leisure',
  _embedded: {
    address: {},
    default_billing_profile: {
      currency: {},
    },
    important_dates: [],
    integration_identities: [],
    tags: [],
    virtuoso: {
      dm: false,
      emcamp: false,
      status: 'cli',
    },
  },
};

const defaultFilters = {
  batches: [],
  categories: [],
  clients: [],
  companies: [],
  dates: {
    from: null,
    month: 1,
    range: 'year',
    to: null,
    quarter: 1,
    year: 'all',
  },
  employees: [],
  fields: {
    date: 'last_active',
    query: [],
  },
  iatas: [],
  queryStrings: [],
  sort: { 'total_revenue': 'desc' },
  statuses: ['active', 'inactive'],
  suppliers: [],
  tags: [],
  types: ['corporate', 'leisure'],
  profile: {
    fields: [],
    mode: 'must'
  },
  virtuoso: {
    emcamp: 'any',
    dm: 'any',
    vl: 'any'
  }
};

const state = {
  aggs: cloneDeep(defaultAggs),
  bulkClientsProcessing: {
    status: false,
    count: 0,
    actionId: null
  },
  client: cloneDeep(defaultClient),
  clients: [],
  dateFields: [
    { key: 'last_active', label: 'Last Active' },
    { key: 'created_at', label: 'Created' },
  ],
  editing: cloneDeep(defaultClient),
  filters: cloneDeep(defaultFilters),
  pagination: {
    page: 1,
    per: 25,
    total: 0,
  },
  query: {},
  queryFields: [
    { key: 'email', label: 'Email' },
    { key: 'first_name', label: 'First Name' },
    { key: 'last_name', label: 'Last Name' },
    { key: 'name.first^3', label: 'First Name' },
    { key: 'name.full', label: 'Full Name' },
    { key: 'phone_number', label: 'Phone Number' },
    { key: '_embedded.address.locality', label: 'Address' },
    { key: '_embedded.address.administrative_area_level_1', label: 'City' },
    { key: '_embedded.address.country', label: 'Country' },
  ],
  refresh: false,
  selected: [],
  sync: false,
  loading: {
    sources: false,
    types: false,
    search: false
  },
};

const mutations = {
  ADD_INTEGRATION_IDENTITY_TO_CLIENT(state, payload) {
    state.editing._embedded.integration_identities.splice(payload.index, 0, payload.val);
  },
  RESET_CLIENTS(state, payload) {
    switch (payload) {
      case 'client':
        state.client = cloneDeep(defaultClient);
        break;
      case 'editing':
        state.editing = cloneDeep(defaultClient);
        break;
      case 'filters':
        state.filters = cloneDeep(defaultFilters);
        break;
      default:
    }
  },
  SET_CLIENT(state, client) {
    if (client.dob) {
      client.dob = dayjs(client.dob, dateFormatYMD).toDate();
    }
    state.client = client;
    state.editing = client;
  },
  SET_CLIENTS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_INTEGRATION_IDENTITY_FIELD_FOR_CLIENT(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.editing._embedded.integration_identities[payload.index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_BULK_CLIENTS_PROCESSING(state, payload) {
    state.bulkClientsProcessing = payload;
  },
  TOGGLE_EDITING_CLIENT_TAG(state, payload) {
    const index = state.editing._embedded.tags.findIndex(
      (tagItem) => tagItem.id === payload.val.id
    );
    if (index < 0) {
      state.editing._embedded.tags.push(payload.val);
    } else {
      state.editing._embedded.tags.splice(index, 1);
    }
  },
  TOGGLE_CLIENTS_FILTER(state, payload) {
    const filter = state.filters[payload.key];
    const { val } = payload;
    const index =
      typeof val === 'string'
        ? filter.indexOf(val)
        : filter.findIndex((filterItem) => filterItem.id === val.id);
    if (index < 0) {
      filter.push(val);
    } else {
      filter.splice(index, 1);
    }
  },
  SET_CLIENTS_FILTERS(state, payload) {
    state.filters = payload;
  }
};

const getters = {
  client: (state) => state.client,
  clientEditing: (state) => state.editing,
  clientSync: (state) => state.sync,
  clients: (state) => state.clients,
  clientsAggregations: (state) => state.aggs,
  clientsTotal: (state) => state.aggs.count?.value || 0,
  clientsProductionData: (state, getters, rootState, rootGetters) => cleanProductionData(getters.clientsAggregations, rootGetters['productionReporting/selectedReportingOption']),
  clientsSearching: (state) => state.loading.search,
  clientsDateFields: (state) => state.dateFields, // used?
  clientsEsQuery(state, getter, rootState, rootGetters) {
    const company = rootGetters['companies/company'];
    const allContractorsWithHiddenAgents = rootGetters['companies/companyContractorsNoAgent'];
    const companyTimeZoneOffset = company.time_zone.offset;
    const filters = state.filters;
    const from = state.pagination.per * (state.pagination.page - 1);
    const currentReportingDates = rootGetters['productionReporting/currentReportingDates'];
    const previousReportingDates = rootGetters['productionReporting/previousReportingDates'];
    const companies = getter.clientsFilters.companies;
    const iatas = getter.clientsFilters.iatas || [];
    const sort = sortMethod(getter.clientsFilters.sort, companies, iatas, currentReportingDates);

    const fromDate = dayjs(filters.dates.from, dateFormatUTC)
      .utcOffset(companyTimeZoneOffset)
      .startOf('day')
      .format(dateFormatUTC);
    const toDate = dayjs(filters.dates.to, dateFormatUTC)
      .utcOffset(companyTimeZoneOffset)
      .endOf('day')
      .format(dateFormatUTC);

    const companyIds = filters.companies.length > 0 ? filters.companies : [company.id];
    const query = {
      bool: {
        must: [{ ...rootGetters['quickSearchFilter/getCompaniesFilter'](companies) },],
        must_not: [],
        should: [],
      }
    };
    if (filters.clients.length > 0) {
      const ids = filters.clients.map((clientItem) => clientItem.id);
      const filteredIds = compact(ids);
      if (filteredIds.length > 0) {
        const terms = { id: filteredIds };
        query.bool.must.push({ terms });
      }
    }
    if (filters.dates.year !== 'all') {
      if (fromDate && toDate) {
        const dateQuery = { range: {} };
        dateQuery.range[filters.fields.date] = { gte: fromDate, lte: toDate };
        query.bool.must.push(dateQuery);
      }
    }

    const contractorsWithHiddenAgents = allContractorsWithHiddenAgents.filter(({ id }) => companyIds.includes(id));

    let hiddenAgents = [];
    contractorsWithHiddenAgents.forEach(contractor => {
      contractor._embedded.employees.forEach(({ id }) => hiddenAgents.push(id));
    })
    const agentIds = [...filters.employees, ...hiddenAgents];

    if (agentIds.length) {
      const agentQuery = {
        bool: {
          should: [
            {
              bool: {
                must: [{ terms: { agent_id: agentIds } }],
              },
            },
          ],
        },
      };
      query.bool.must.push(agentQuery);
    }
    if (filters.statuses?.length == 1) {
      const { statuses } = filters;
      const active = statuses.includes('active');
      query.bool.must.push({ match: { is_active: active } });
    }
    if (filters.queryStrings && filters.queryStrings.length > 0) {
      const queryStringsQuery = {
        bool: {
          should: [],
        },
      };
      filters.queryStrings.forEach((queryString) => {
        if (queryString) {
          queryStringsQuery.bool.should.push({
            multi_match: {
              query: queryString.toUpperCase(),
              fields: filters.fields.query,
              type: 'bool_prefix',
              operator: 'and',
              fuzziness: 1
            },
          });
        }
      });
      query.bool.must.push(queryStringsQuery);
    }
    if (filters.tags.length > 0) {
      filters.tags.forEach((tag) => {
        query.bool.must.push({ term: { tags: tag } });
      });
    }
    if (filters.types.length > 0) {
      query.bool.must.push({ terms: { type: filters.types } });
    }

    const virtuosoFilterKeys = Object.keys(filters.virtuoso);
    virtuosoFilterKeys.forEach((virtuosoFilterKey) => {
      const virtuosoFilterValue = filters.virtuoso[virtuosoFilterKey];
      if (virtuosoFilterValue !== 'any') {
        const virtuosoQuery = {
          match: {[`_embedded.virtuoso.${virtuosoFilterKey}`]: true }
        };
        if (virtuosoFilterValue) {
          query.bool.must.push(virtuosoQuery);
        } else {
          query.bool.must_not.push(virtuosoQuery);
        }
      }
    });

    const { fields: profileFilterFields, mode: profileFilterMode } = filters.profile;
    if (profileFilterFields.length > 0) {
      profileFilterFields.forEach((profileFilterField) => {
        const profileQuery = {
          exists: { field: profileFilterField }
        }
        query.bool[profileFilterMode].push(profileQuery);
      });
    }

    const clientProductionReportingFields = (fieldName = '') => {
      return productionReportingFields(
        fieldName,
        companies,
        iatas,
        currentReportingDates
      )
    }

    const aggs = {
      'count': {
        'cardinality': {
          'field': '_id'
        }
      },
      'production_reports': {
        'nested': {
          'path': '_embedded.production_reports'
        },
        'aggs': {
          'current_period': productionAggs(companies, iatas, currentReportingDates),
        }
      }
    };

    if (previousReportingDates.startDate && previousReportingDates.endDate) {
      aggs.production_reports.aggs['previous_period'] = productionAggs(companies, iatas, previousReportingDates);
    }

    const payload = {
      _source: { excludes: '_embedded.production_reports' },
      aggs,
      size: state.pagination.per,
      from: from >= 0 ? from : 0,
      sort,
      query: {
        constant_score: {
          filter: query,
        },
      },
      script_fields: {
        total_commission: clientProductionReportingFields('commission'),
        total_revenue: clientProductionReportingFields('revenue'),
        total_booking_count: clientProductionReportingFields('booking_count')
      }
    };
    return payload;
  },
  clientEsQuery: (state, getter, rootState, rootGetters) => (clientId) =>  {
    const companies = getter.clientsFilters.companies || [];
    const iatas = getter.clientsFilters.iatas || [];
    const currentReportingDates = rootGetters['productionReporting/currentReportingDates'];
    const previousReportingDates = rootGetters['productionReporting/previousReportingDates'];

    const query = {
      bool: {
        must: [
          {
            bool: {
              must: [
                { ...rootGetters['quickSearchFilter/getCompaniesFilter'](companies) },
              ]
            }
          },
          {
            bool: {
              must: [{ term: { 'id': clientId } }]
            }
          }
        ]
      },
    }
    const clientProductionReportingFields = (fieldName = '') => {
      return productionReportingFields(
        fieldName,
        companies,
        iatas,
        currentReportingDates
      )
    }

    const aggs = {
      'production_reports': {
        'nested': {
          'path': '_embedded.production_reports'
        },
        'aggs': {
          'current_period': productionAggs(
            companies,
            iatas,
            currentReportingDates
          ),
        }
      }
    };

    if (previousReportingDates.startDate && previousReportingDates.endDate) {
      aggs.production_reports.aggs['previous_period'] = productionAggs(
        companies,
        iatas,
        previousReportingDates
      );
    }

    return  {
       _source: { excludes: '_embedded.production_reports' },
      aggs,
      query: {
        constant_score: {
          filter: query,
        },
      },
      script_fields: {
        total_commission: clientProductionReportingFields('commission'),
        total_revenue: clientProductionReportingFields('revenue'),
        total_booking_count: clientProductionReportingFields('booking_count')
      }
    };
  },
  clientsFilters: (state) => state.filters,
  clientsPagination: (state) => state.pagination,
  clientsQueryFields: (state) => state.queryFields,
  clientsRefresh: (state) => state.refresh,
  clientsSelected: (state) => state.selected,
  bulkClientsProcessingInfo: (state) => state.bulkClientsProcessing,
};

const actions = {
  async doGetClient({ commit }, payload) {
    const { data } = await this._vm.$api.clients.get(payload);
    commit('SET_CLIENT', data.clients[0]);
  },
  async doSearchClients({ commit }, payload) {
    commit('SET_CLIENTS_ATOMIC', { key: 'loading.search', val: true });
    const { ignore, ...params } = payload;
    const { data } = await this._vm.$api.clients.search(params);

    const clients = data.hits.hits.map((hit) => {
      const scriptFields = {};
      Object.keys(hit.fields).forEach((key) => {
        const val = hit.fields[key];
        [scriptFields[key]] = val;
        return val;
      })
      return Object.assign(hit._source, scriptFields);
    });
    if (!ignore) {
      commit('SET_CLIENTS_ATOMIC', { key: 'clients', val: clients });
      commit('SET_CLIENTS_ATOMIC', { key: 'aggs', val: data.aggregations });
      commit('SET_CLIENTS_ATOMIC', { key: 'pagination.total', val: data.hits.total.value });
      commit('SET_CLIENTS_ATOMIC', { key: 'refresh', val: false });
    }
    commit('SET_CLIENTS_ATOMIC', { key: 'loading.search', val: false });

    return {
      clients,
      aggs: data.aggregations,
    };
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
